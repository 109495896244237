import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import './index.css';
import App from './App';

import "./i18n";

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// import "./i18n";

import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './Redux';
import { CookiesProvider } from "react-cookie";

import { ThemeUIProvider } from 'theme-ui';

import theme from "./theme";

// const API_KEY = `AIzaSyDHwZWzzJZPqvIHleNmWIPlyDuDzF7uypg`;
// const client_id = `685075310296-te21oid6s9j9luu8gu20d9vo1582v8cl.apps.googleusercontent.com`; //kb
// const client_id = `868736241378-fuom5qbd7n6aqdso63gg8qbs70koi9r0.apps.googleusercontent.com`; //mh
const client_id = `719904239684-5jafiabilfu0a5qe40vmudll12useeae.apps.googleusercontent.com`; //ofc

const goldenEnvironment = window.globalConfig?.GOLDEN || ``;
const chocolateEnvironment = window.globalConfig?.CHOCOLATE || ``;
const grayEnvironment = window.globalConfig?.GRAY || ``;
const darkEnvironment = window.globalConfig?.DARK || ``;
const darkGrayEnvironment = window.globalConfig?.DARKGRAY || ``;
const redEnvironment = window.globalConfig?.RED || ``;
const lightGreenEnvironment = window.globalConfig?.LIGHTGREEN || ``;
const placeholderEnvironment = window.globalConfig?.PLACEHOLDER || ``;
const blackishAshEnvironment = window.globalConfig?.BLACKISHASH || ``;
const whiteEnvironment = window.globalConfig?.WHITE || ``;
const blackEnvironment = window.globalConfig?.BLACK || ``;
const lightBlueEnvironment = window.globalConfig?.LIGHTBLUE || ``;
const blueEnvironment = window.globalConfig?.BLUE || ``;

// Inject the root color into CSS variables
document.documentElement.style.setProperty("--golden", goldenEnvironment);
document.documentElement.style.setProperty("--chocolate", chocolateEnvironment);
document.documentElement.style.setProperty("--gray", grayEnvironment);
document.documentElement.style.setProperty("--dark-gray", darkGrayEnvironment);
document.documentElement.style.setProperty("--dark", darkEnvironment);
document.documentElement.style.setProperty("--red", redEnvironment);
document.documentElement.style.setProperty("--light-green", lightGreenEnvironment);
document.documentElement.style.setProperty("--placeholder", placeholderEnvironment);
document.documentElement.style.setProperty("--blackish-ash", blackishAshEnvironment);
document.documentElement.style.setProperty("--white", whiteEnvironment);
document.documentElement.style.setProperty("--black", blackEnvironment);
document.documentElement.style.setProperty("--light-blue", lightBlueEnvironment);
document.documentElement.style.setProperty("--blue", blueEnvironment);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <GoogleOAuthProvider clientId={client_id}>
      <CookiesProvider>
        <Provider store={store}>
          <ThemeUIProvider theme={theme}>
            <App />
          </ThemeUIProvider>
        </Provider>
      </CookiesProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
